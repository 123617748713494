import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../../src/components/modules/Layout"
import ProductHeader from "../../../src/components/blocks/ProductHeader"
import ProductCard from "../../../src/components/molecules/ProductCard"
import Seo from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Seo"

const ProductAreaTemplate = ({ data }) => {
  const entry = data.entry
  const variants = data.variants.edges
  const productLines = data.productLines.edges
  let products = data.products.edges

  products.forEach((product) => {
    const allVariants = variants.filter(
      (v) =>
        v.node.parentId === product.node.ID &&
        v.node.language === product.node.language
    )
    const linkedVariant = allVariants[Math.floor((allVariants.length - 1) / 2)]
    if (linkedVariant) {
      product.node.variantFullSlug = linkedVariant.node.fullSlug
    }
  })

  let breadcrumbs = [
    { link: entry.rootPage.fullSlug, link_text: entry.rootPage.name },
  ]

  return (
    <Layout language={entry.language}>
      <Seo
        title={`${entry.name} | ${process.env.GATSBY_PRODUCT_AREAS_BASE}`}
        no_description_fallback={true}
        language={entry.language}
      />
      <ProductHeader
        block={{
          headline: entry?.name,
        }}
        breadcrumbs={breadcrumbs}
      />

      <section className="py-block">
        <div className=" block-w-container">
          <div className="flex flex-row flex-wrap -mx-grid">
            <div className="w-full mb-8 md:w-1/3 lg:w-1/4 px-grid">
              <div className="py-4 border border-gray-100">
                {productLines.map((group, index) => {
                  return (
                    <div key={group.node.fullSlug} className="block">
                      <Link
                        className="inline-block py-2 text-sm px-grid hover:text-primary"
                        key={index}
                        to={group.node.fullSlug}
                      >
                        {group.node.name}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="w-full md:w-2/3 lg:w-3/4 px-grid">
              <div className="flex flex-row flex-wrap -mx-grid">
                {products.map((product) => (
                  <div
                    className="flex w-full mb-8 px-grid md:w-1/2 lg:w-1/3"
                    key={product.node.fullSlug}
                  >
                    <ProductCard
                      item={{
                        id: product.node.ID,
                        headline: product.node.name,
                        text: product.node.hierarchy.group.name,
                        image: product.node.mainImage?.value || undefined,
                        treeSorting: product.node.treeSorting,
                        link:
                          product.node.variantFullSlug || product.node.fullSlug,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {entry?.properties?.description?.length > 0 && (
        <section>
          <div className="pb-block block-w-container">
            <div className="flex flex-row flex-wrap -mx-grid">
              <div className="w-full lg:w-1/4 px-grid "></div>
              <div className="w-full lg:w-2/4 px-grid ">
                <Richtext text={entry?.properties?.description} />
              </div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  )
}

export const query = graphql`
  query AreaQuery($id: String, $language: String) {
    entry: contentServProductArea(
      ID: { eq: $id }
      language: { eq: $language }
    ) {
      name
      externalKey
      language
      ID
      fullSlug
      rootPage {
        name
        fullSlug
      }
      description {
        value
      }
      image {
        value
      }
    }
    products: allContentServProduct(
      filter: {
        hierarchy: { area: { id: { eq: $id } } }
        language: { eq: $language }
      }
      sort: { fields: treeSorting, order: ASC }
    ) {
      edges {
        node {
          name
          ID
          treeSorting
          language
          fullSlug
          parentId
          externalKey
          mainImage {
            value
          }
          hierarchy {
            area {
              id
            }
            group {
              name
              id
            }
          }
        }
      }
    }
    variants: allContentServVariant(
      filter: {
        hierarchy: { area: { id: { eq: $id } } }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          language
          fullSlug
          parentId
        }
      }
    }
    productLines: allContentServProductLine(
      filter: {
        hierarchy: { area: { id: { eq: $id } } }
        language: { eq: $language }
      }
      sort: { fields: treeSorting, order: ASC }
    ) {
      edges {
        node {
          name
          ID
          fullSlug
          externalKey
        }
      }
    }
  }
`

export default ProductAreaTemplate
